import React, { memo, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Box, Typography, CircularProgress, } from '@mui/material';
import { useTheme } from '@mui/styles';

import { tradingAmountFormat } from '@utils/Tools';

import { IoWarningOutline } from "react-icons/io5";

const PercentCircular = memo((props) => {
    const theme = useTheme();
    const { value, max, color, barColor } = props;
    const normalizedMax = max !== null && max !== 0 ? max : 1;
    const percentage = isNaN(value) ? 0 : (value / normalizedMax) * 100;

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
                background: 'transparent',
                borderRadius: '50%',
                overflow: 'hidden',
            }}
        >
            <CircularProgress
                variant='determinate'
                value={100}
                size={50}
                thickness={5}
                sx={{ color: barColor || '#00000033' }}
            />
            <CircularProgress
                variant='determinate'
                value={percentage}
                size={50}
                thickness={5}
                sx={{ color: color, position: 'absolute', left: 0, strokeLinecap: 'round' }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color={color}>{`${Math.round(percentage)}%`}</Typography>
            </Box>
        </Box>
    );
})

const MerchantTradeSell = props => {
    let { item , needDivider =false } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [circularComponent, setCircularComponent] = useState(null);
    const [content, setContent] = useState(null);
    console.log(item)
    const dateObject = new Date(item?.created_at);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = dateObject.toLocaleString('default', { month: 'short' });

    const statusChange = () => {
        let circularComponent = null;
        let content = null;

        if (parseInt(item?.status) === 10) {
            circularComponent = <PercentCircular value={item?.sold_unit} max={item?.trade_unit} color={theme.palette.white.main} barColor={theme.palette.darkBase.main} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.pendingUnit', { value: item?.trade_unit })}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: '#787878' }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        } else if (parseInt(item?.status) === 20) {
            circularComponent = <PercentCircular value={item?.sold_unit} max={item?.trade_unit} color={theme.palette.white.main} barColor={theme.palette.darkBase.main} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.sellingUnit', { value: item?.trade_unit })}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: '#787878' }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        } else if (parseInt(item?.status) === 30) {
            circularComponent = <PercentCircular value={item?.sold_unit} max={item?.trade_unit} color={theme.palette.white.main} barColor={theme.palette.darkBase.main} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.soldValueUnit', { value: item?.sold_unit })}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: '#787878' }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        } else if (parseInt(item?.status) === 40) {
            circularComponent = <PercentCircular value={item?.sold_unit} max={item?.trade_unit} color={theme.palette.gray.main} barColor={theme.palette.darkBase.main} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.returnUnit', { value: item?.sold_unit })}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: theme.palette.darkGray.main }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        } else if (parseInt(item?.status) === 50) {
            circularComponent = <IoWarningOutline style={{ width: '40px', height: '40px', marginLeft: 10 }} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.failed')}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: theme.palette.darkGray.main }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        } else if (parseInt(item?.status) === 10) {
            circularComponent = <PercentCircular value={item?.sold_unit} max={item?.trade_unit} color={theme.palette.gray.main} barColor={theme.palette.darkBase.main} />;
            content = (
                <>
                    <p style={{ fontWeight: 700 }}>{t('trade.returnUnit', { value: item?.sold_unit })}</p>
                    <p className='fs-14' style={{ fontWeight: 500, color: theme.palette.darkGray.main }}>{` ${t('trade.sellingRate')}  ${item?.trade?.price}`}</p>
                </>
            );
        }

        return { circularComponent, content };
    }

    useEffect(() => {
        if (item?.status) {
            const { circularComponent, content } = statusChange();
            circularComponent && setCircularComponent(circularComponent); // Assuming you have a state for circularComponent
            content && setContent(content); // Assuming you have a state for content
        }

        // eslint-disable-next-line
    }, [item?.status]);

    return (
        <div>
            { needDivider && <div className='divider-black p-lr-15' style={{ height: 3 }} /> }
            <div className='flex-sb-m p-b-10  bor-10 p-tb-10 p-lr-15' style={{ color: theme.palette.white.main }}>
                <div className='flex-m'>
                    <div style={{ flexDirection: 'column', marginRight: 20 }} className='flex-c-m'>
                        <Typography variant="h5" style={{ textAlign: 'center', color: theme.palette.white.main, lineHeight: "1.2" }}>
                            {day}
                        </Typography>
                        <Typography variant="body2" style={{ textAlign: 'center', color: theme.palette.white.main, textTransform: "uppercase", letterSpacing: "2px" }}>
                            {month}
                        </Typography>
                    </div>
                    {circularComponent}
                    <div className='p-l-15'>
                        {content}
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className='flex-c-m' >
                        <p style={{ fontWeight: 700 }}>{item?.trade_unit}</p>
                        <p style={{ fontWeight: 700, textTransform: 'uppercase', marginLeft: 5 }}>{t('trade.unit')}</p>
                    </div>
                    <p style={{ fontWeight: 500, fontSize: 12, color: theme.palette.darkGray.main }}>{`≈ $ ${tradingAmountFormat(parseFloat(item?.trade_unit * item?.trade?.price).toFixed(3))}`}</p>
                </div>
            </div>
        </div>
    )
}

export default MerchantTradeSell;