import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Box, Button, IconButton, Stepper, Step, StepLabel, StepContent, Divider, TextField, MenuItem, InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";

import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { storePaths, currencyFormat } from '@utils/Tools';

import { FaShop } from "react-icons/fa6";

import "@css/tree.css";

const INITIAL_STATE = {
    username: '',
    paymentMethod: 0,
    isSystemPayment: false,
    securityPassword: '',
    showSecurityPassword: false,
    // tradeType: 'lock_trade',
};

export default function Plans() {
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();

    const [activePlans, setActivePlans] = useState({});
    const [activeMerchants, setActiveMerchants] = useState({});
    const [limitPlansIds, setLimitPlansIds] = useState({});
    // const [openPlan, setOpenPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({});
    const [selectedMerchant, setSelectedMerchant] = useState({});
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const [wallets, setWallets] = useState({});
    const [result, setResult] = useState({});
    const [paymentMethods, setPaymentMethods] = useState({});
    const [tradeDetail, setTradeDetail] = useState({});
    const [state, setState] = useState(INITIAL_STATE);
    const [isExtraSmall, setIsExtraSmall] = useState(false);
    // const tradeList = [{ value: "auto_sell", label: t('plan.autoSell') }, { value: "lock_trade", label: t('plan.lockTrade') }];
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/active-plans-merchants`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                
                let { status, message, error, data , limit} = response;

                if (status) {
                    setActiveMerchants(response.merchants);
                    setActivePlans(response.plans);
                    setLimitPlansIds(limit);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            }
        });

        getUrl(`/payment-methods`).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;

                if (status) {
                    setWallets(data.wallets);
                    // setPaymentMethods(data.payment_methods);
                    setTradeDetail(data.trade_detail);
                }
            }
        }).catch(error => {
            if (isMountedRef.current) {
                addAlert(JSON.stringify(error.message));
            }
        });

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handleResize = () => {
          setIsExtraSmall(window.innerWidth <= 344);
        };
    
        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);

    
        // Call handleResize once when component mounts to set initial state
        handleResize();
    
        // Cleanup function to remove event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);  

    //stepper
    const [activeStep, setActiveStep] = React.useState(0);

    const steps = [
        t('plan.merchant'),
        t('plan.title'),
        t('plan.makePayment'),
        t('plan.paymentSuccess'),
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setSelectedMerchant({});
        setSelectedPlan({});
        setSelectedPaymentMethods({});
        setResult({});
        setState(INITIAL_STATE);
    };

    const handleNextStep = (type, data) => {
        if (type === 'plan') {
            setSelectedPlan(data);
        } else if (type === 'merchant') {
            setSelectedMerchant(data);
            setSelectedPlan({});
            setSelectedPaymentMethods({});
        }
        handleNext();
    }

    const handleChange = (event) => {
        let { name, value } = event.target;
        if(name === 'username'){
            setState({ ...state, [name]: value.replace(/[^\w\s]/gi, "").toLowerCase().replace(/ /g, '') });
        }else if(name === 'paymentMethod'){
            let is_system_payment = false;
            // _.map(paymentMethods, (paymentRules, combinationId) => {
            //     if (value === combinationId) {
            //         console.log('true',true);
            //         is_system_payment = true;
            //     } else {
            //         console.log('false',false);
            //         is_system_payment = false;
            //     }
            // })

            // if (!is_system_payment) {
            //     setSelectedPaymentMethods(selectedPlan?.extra_setting?.payment_method?.[value-1]);
            // }
            setState({ ...state, [name]: value });
        }else{
            setState({ ...state, [name]: value });
        }
        
    }

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showSecurityPassword: !state.showSecurityPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // confirm purchase
    const purchasePlan = () => {
        setLoading(true);
        setInputErrors();
        let apiData = {
            username: state.username,
            package_id: selectedPlan.id,
            merchant_id: selectedMerchant.id,
            combination_id: state.paymentMethod,
            is_system_payment: state.isSystemPayment,
            selected_payment_method: selectedPaymentMethods,
            security_password: state.securityPassword,
            // auto_sell: state.tradeType === 'auto_sell' ? true : false,
            // lock_trade: state.tradeType === 'lock_trade' ? true : false,
        };
        postUrl(`/purchase-plan`, apiData).then(response => {
            setLoading(false);
            let { status, message, errors, data } = response;
            if (status) {
                setResult(data);
                handleNext();
                addAlert('', message || t('success.purchaseSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.purchaseError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    // calculate service fee and total amount
    // let serviceFee = 0;
    let totalAmount = 0;

    // if (_.size(selectedPlan?.service_fee) > 0) {
    //     if (selectedPlan?.service_fee?.['type'] === 'percent') {
    //         serviceFee = selectedPlan?.price * (parseFloat(selectedPlan?.service_fee?.['amount']) / 100);
    //     } else {
    //         serviceFee = parseFloat(selectedPlan?.service_fee?.['amount']);
    //     }
    // }
    // totalAmount = parseFloat(selectedPlan?.price) + serviceFee;
    totalAmount = parseFloat(selectedPlan?.price);

    // step contents
    const stepOne = () => {
        return (
            <div className='p-lr-15'>
                <Grid container spacing={2} sx={{ flexGrow: 1 }} >
                    {_.size(activeMerchants) > 0 ? (
                        _.map(activeMerchants, (merchant, index) => (
                            <Grid item xs={isExtraSmall ? 12 : 6} sm={3} lg={2} key={index} style={{ padding: '70px 10px 20px' }}>
                                <div className='p-b-20 p-lr-10 pos-relative s-full flex-col flex-sb bor-10 gradient-gold-border'>
                                    <div className='p-lr-10'>
                                        <div className='w-full bor-20 gradient-gold-border p-all-10' style={{ marginTop: -50, aspectRatio: 1 / 1 }}>
                                            <img src={merchant?.logo ? merchant?.logo?.file_name : '/images/logos/logo.png'} className='w-full bor-20' alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1 }} />
                                        </div>
                                        <div className='txt-center p-b-10 w-full p-t-20'>
                                            <Typography variant='body1' className='p-b-10 text-gold-short' style={{  lineHeight: 1.1 }}>
                                                <b>
                                                    {merchant?.name ? merchant?.name?.[i18n.language] : merchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}
                                                    <br />({merchant?.code})
                                                </b>
                                            </Typography>
                                            {/* <Typography variant='body2' style={{ color: '#AEAEAE', minHeight: 20, wordBreak: 'break-all' }}>{merchant?.email ? merchant?.email : ""}</Typography> */}
                                            <Typography variant='body2'  noWrap className='txt-center txt-content' style={{minHeight: 20}}>{merchant?.description ? merchant?.description : ""}</Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            variant="contained"
                                            className={selectedMerchant.id === merchant.id ? 'medium-button-selected' : ''}
                                            style={{ width: '100%', minWidth: 'unset' }}
                                            fullWidth
                                            onClick={() => handleNextStep('merchant', merchant)}
                                        >
                                            {t('button.select')}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        ))
                    ) : (
                        <Typography className='txt-center w-full'>{t('general.noData')}</Typography>
                    )}
                </Grid>
            </div>
        )
    };

    const stepTwo = () => {
        return (
            <div className='p-lr-15'>
                {selectedMerchant?.id ? (
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        {_.size(activePlans) > 0 ? (
                            _.map(activePlans, (detail, index) => {
                                const merchantIdFromDetail = detail?.extra_setting?.merchant_id;
                                const selectedMerchantId = selectedMerchant?.id;
                                let matchPlan = String(merchantIdFromDetail) === String(selectedMerchantId);
    
                                if (matchPlan) {
                                    return (
                                        <Grid item xs={isExtraSmall ? 12 : 6} sm={3} lg={2} key={index} style={{ padding: '70px 10px 20px' }}>
                                            <div className='p-b-15 p-lr-10 pos-relative s-full flex-col flex-sb bor-10 gradient-gold-border'>
                                                <div style={{ padding: '0px 10px' }}>
                                                    <div className='w-full bor-20 gradient-gold-border p-all-10' style={{ marginTop: -50, aspectRatio: 1 / 1 }}>
                                                        <img src={detail?.plan_logo ? detail?.plan_logo?.file_name : '/images/logos/logo.png'} className='w-full bor-20' alt="plan_logo" loading='lazy' style={{ aspectRatio: 1 / 1 }} />
                                                    </div>
                                                <div className='txt-center p-b-10 w-full p-t-20'>
                                                    <Typography variant='body1' className='p-b-10 text-gold-short' style={{lineHeight: 1.1 }}>
                                                        <b>{detail?.name ? detail?.name?.[i18n.language] : detail?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b>
                                                    </Typography>
                                                    <Typography variant='body2' style={{minHeight: 20, fontWeight:500 }}>
                                                        {_.size(detail.description) > 0 ? (detail?.description?.[i18n.language]?.title || detail?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title) : ""}
                                                    </Typography>
                                                    <Typography variant='body2' style={{minHeight: 20, fontWeight:500 }}>
                                                        {_.size(detail.description) > 0 ? (detail?.description?.[i18n.language]?.subtitle || detail?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle) : ""}
                                                    </Typography>
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        className={selectedPlan.id === detail?.id ? 'medium-button-selected' : ''}
                                                        style={{ width: '100%', minWidth: 'unset' }}
                                                        fullWidth
                                                        onClick={() => handleNextStep('plan', detail)}
                                                    >
                                                        $ {currencyFormat(detail?.price,2)}
                                                    </Button>
                                                </div>
                                            </div>
                                            </div>
                                        </Grid>
                                    );
                                }
                                return null; // Ensure we return null if the plan does not match
                            })
                        ) : (
                            <p className='txt-empty w-full'>{t('general.noData')}</p>
                        )}
                    </Grid>
                ) : (
                    <p className='txt-empty w-full'>{t('general.noData')}</p>
                )}
    
                <div className='flex-c w-full p-all-10'>
                    <Button
                        variant="contained"
                        className='medium-button-selected pos-absolute'
                        onClick={handleBack}
                    >
                        {t('button.back')}
                    </Button>
                </div>
            </div>
        );
    };       

    const stepThree = () => {
        return (
            <div className='p-tb-30 p-lr-15'>
                <div className='p-b-15 p-lr-15 pos-relative bor-10 gradient-gold-border flex-m flex-col'>
                    <div className='w-full pos-relative' style={{ top: 20, paddingBottom: 20 }}>
                        <Grid container spacing={2} style={{ padding: '30px 0' }}>
                            <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                                <img src={selectedPlan?.plan_logo ? selectedPlan?.plan_logo?.file_name : '/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ width: '50%', aspectRatio: 1 / 1 }} />
                                <div className='txt-center p-b-10 w-full p-t-20 flex-col flex-m'>
                                    <Typography variant='h6' className='p-b-10 text-gold-short' style={{lineHeight: 1.1 }}>
                                        <b>{selectedPlan?.name ? selectedPlan?.name?.[i18n.language] : selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b>
                                    </Typography>
                                    {
                                        _.size(selectedPlan.description) > 0
                                            ?
                                            <p className="txt-content" style={{minHeight: 20 }}>
                                                {(selectedPlan?.description?.[i18n.language]?.title ? selectedPlan?.description?.[i18n.language]?.title : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title)}
                                            </p>
                                            :
                                            ""
                                    }
                                    {
                                        _.size(selectedPlan.description) > 0
                                            ?
                                            <p className="txt-content" style={{minHeight: 20 }}>
                                                {(selectedPlan?.description?.[i18n.language]?.subtitle ? selectedPlan?.description?.[i18n.language]?.subtitle : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle)}
                                            </p>
                                            :
                                            ""
                                    }
                                    {/* <div style={{ marginBottom: 10, height: 2, width: '70%', background: '#F1F1F1', boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF' }} /> */}
                                    <p className='txt-content lh-11 flex-m'>
                                        <FaShop style={{ marginRight: 5 }} />
                                        {selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}
                                    </p>
                                    <p className='txt-content lh-11'>
                                        {selectedMerchant?.email ? selectedMerchant?.email : ""}
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                            variant="standard"
                                            placeholder={t('user.username')}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            autoComplete="username"
                                            name='username'
                                            value={state?.username}
                                            onChange={handleChange}
                                            className={styles.textFieldStyle}
                                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                            error={inputErrors && inputErrors.username ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '10px', padding: "15px 0px 15px 15px" } }}
                                            variant="standard"
                                            select
                                            placeholder={t('plan.paymentMethod')}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            autoComplete="paymentMethod"
                                            name='paymentMethod'
                                            value={state?.paymentMethod}
                                            onChange={handleChange}
                                            helperText={inputErrors && inputErrors.combination_id ? inputErrors.combination_id : ''}
                                            error={inputErrors && inputErrors.combination_id ? true : false}
                                        >
                                            <MenuItem value={0} disabled>{t('general.pleaseSelect')}</MenuItem>
                                            {/* {console.log(_.size(selectedPlan.payment_methods))} */}
                                            {
                                                _.size(selectedPlan?.payment_methods) > 0
                                                    ? _.map(selectedPlan?.payment_methods, (detail, idx) => {
                                                        
                                                        let combinationStr = '';
                                                        _.map(detail?.wallet, (ammount, walletId) => {
                                                            // console.log('walletId',walletId);
                                                            combinationStr = combinationStr ? (combinationStr + ' + ') : '';
                                                            if(walletId === 'trade'){
                                                                combinationStr += tradeDetail?.name?.[i18n.language] +( detail?.type === 'percent' ? ` [${parseFloat([ammount]).toFixed(2)}%]` : ` [${(parseFloat([ammount])).toFixed(0)}]`)
                                                            }else{
                                                                let method = _.find(wallets, { 'id': parseInt(walletId) });
                                                                combinationStr += (method?.name ? method?.name?.[i18n.language] : method?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE])
                                                                + (detail?.type === 'percent' ? ` [${parseFloat([ammount]).toFixed(2)}%]` : ` [${(parseFloat([ammount])).toFixed(method?.decimal)}]`);
                                                            }
                                                        })
                                                        return (
                                                            <MenuItem key={idx+1} value={idx}>
                                                                {combinationStr}
                                                            </MenuItem>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ '& .MuiInputBase-root': { borderRadius: '10px' } }}
                                            variant="standard"
                                            placeholder={t('user.securityPassword')}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle securityPassword visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {state?.showSecurityPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            name='securityPassword'
                                            type={state.showSecurityPassword ? 'text' : 'password'}
                                            color='primary'
                                            value={state.securityPassword}
                                            onChange={handleChange}
                                            className={styles.textFieldStyle}
                                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                                            error={inputErrors && inputErrors.security_password ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className='txt-content'>{t('plan.paymentDetails')}</p>
                                        {
                                            selectedPlan?.payment_methods[state.paymentMethod] && (
                                                    <>
                                                        {Object.entries(selectedPlan?.payment_methods[state.paymentMethod]['wallet']).map(([walletId, percentage]) => {
                                                            let name = '';
                                                            let rate = 0;
                                                            let decimal = 0;
                                                            let prefix = '';
                                                            let rate_display = '';
                                                            let amount = 0;
                                                            let walletAmount = 0;
                                                            // console.log('testing',percentage);
                                                            if(selectedPlan?.payment_methods[state.paymentMethod]['type'] === 'percent'){
                                                                walletAmount = Number(selectedPlan.price * percentage / 100).toFixed(2);
                                                            }else{
                                                                walletAmount = Number(percentage).toFixed(2);
                                                            }
                                                            
                                                            
                                                            if (walletId == 'trade') {
                                                                name = tradeDetail.name[i18n.language];
                                                                rate = tradeDetail.rate;
                                                                decimal = 0;
                                                                prefix = tradeDetail.prefix
                                                                rate_display = '1 ' + prefix + ' ' + name + ' ≈ $' + tradeDetail.current_price;
                                                                amount = walletAmount / tradeDetail.current_price;
                                                            } else {
                                                                const wallet = wallets.find(wallet => wallet.id === parseInt(walletId));
                                                                if (!wallet) {
                                                                    return null;
                                                                }
                                                                name = wallet.name[i18n.language] || wallet.name[process.env.REACT_APP_DEFAULT_LANGUAGE];
                                                                rate = wallet.rate;
                                                                decimal = wallet.decimal;
                                                                rate_display = rate + ' ' + name + ' = $1';
                                                                amount = walletAmount * rate;
                                                            }
                                                            return (
                                                                <div key={walletId} className={(mobileView ? 'flex-t p-b-5' : 'flex-m') + ' dis-flex flex-sb'}>
                                                                    <div className={mobileView ? '' : 'flex-c-m'}>
                                                                        <p className='fs-15 txt-theme'>
                                                                            {currencyFormat((Math.ceil(amount * (10 ** decimal)) / (10 ** decimal)).toFixed(decimal), decimal) + ' ' + (prefix != '' ? prefix : '') + ' ' + name}
                                                                        </p>
                                                                        <p className='p-l-5 fs-13 txt-white'>
                                                                            {`(${rate_display})`}
                                                                        </p>
                                                                    </div>
                                                                    <p className='fs-15 txt-theme'>
                                                                        $ {currencyFormat(walletAmount, decimal)}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )
                                        }
                                        {/* {
                                            state?.isSystemPayment
                                                ? paymentMethods[state.paymentMethod] && (
                                                    <>
                                                        {Object.entries(paymentMethods[state.paymentMethod]).map(([walletId, percentage]) => {
                                                            let name = '';
                                                            let rate = 0;
                                                            let decimal = 0;
                                                            let prefix = '';
                                                            let rate_display = '';
                                                            let amount = 0;
                                                            const walletAmount = (selectedPlan.price * percentage / 100).toFixed(2);
                                                            if (walletId == 'trade') {
                                                                name = tradeDetail.name[i18n.language];
                                                                rate = tradeDetail.rate;
                                                                decimal = 0;
                                                                prefix = tradeDetail.prefix
                                                                rate_display = '1 ' + prefix + ' ' + name + ' ≈ $' + tradeDetail.current_price;
                                                                amount = walletAmount / tradeDetail.current_price;
                                                            } else {
                                                                const wallet = wallets.find(wallet => wallet.id === parseInt(walletId));
                                                                if (!wallet) {
                                                                    return null;
                                                                }
                                                                name = wallet.name[i18n.language] || wallet.name[process.env.REACT_APP_DEFAULT_LANGUAGE];
                                                                rate = wallet.rate;
                                                                decimal = wallet.decimal;
                                                                rate_display = rate + ' ' + name + ' = $1';
                                                                amount = walletAmount * rate;
                                                            }
                                                            return (
                                                                <div key={walletId} className={(mobileView ? 'flex-t p-b-5' : 'flex-m') + ' dis-flex flex-sb'}>
                                                                    <div className={mobileView ? '' : 'flex-c-m'}>
                                                                        <p className='fs-15 txt-theme'>
                                                                            {currencyFormat((Math.ceil(amount * (10 ** decimal)) / (10 ** decimal)).toFixed(decimal), decimal) + ' ' + (prefix != '' ? prefix : '') + ' ' + name}
                                                                        </p>
                                                                        <p className='p-l-5 fs-13 txt-white'>
                                                                            {`(${rate_display})`}
                                                                        </p>
                                                                    </div>
                                                                    <p className='fs-15 txt-theme'>
                                                                        $ {currencyFormat(walletAmount, decimal)}
                                                                    </p>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                )
                                                : _.size(selectedPaymentMethods) > 0 && state?.paymentMethod &&
                                                _.map(selectedPaymentMethods?.['wallet_ids'], (method, idx) => {
                                                    let amount = 0;
                                                    let wallet = wallets.find(wallet => wallet.id === parseInt(method));
                                                    if (!wallet) {
                                                        return null;
                                                    }
                                                    let decimal = wallet.decimal;
                                                    let name = wallet?.name?.[i18n.language] || wallet?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE];
                                                    let rate = wallet.rate;
                                                    let rate_display = rate + ' ' + name + ' = $1';
                                                    let walletAmount = 0;

                                                    if (selectedPaymentMethods?.['type'] === 'fixed') {
                                                        walletAmount = (parseFloat(selectedPaymentMethods?.['amounts']?.[idx])).toFixed(wallet.decimal);
                                                    } else {
                                                        walletAmount = (selectedPlan.price * (selectedPaymentMethods?.['amounts']?.[idx]) / 100).toFixed(wallet.decimal);
                                                    }
                                                    amount = walletAmount * rate;

                                                    return (
                                                        <div key={idx} className={(mobileView ? 'flex-t p-b-5' : 'flex-m') + ' dis-flex flex-sb'}>
                                                            <div className={mobileView ? '' : 'flex-c-m'}>
                                                                <p className='fs-15 txt-theme'>
                                                                    {currencyFormat((Math.ceil(amount * (10 ** decimal)) / (10 ** decimal)).toFixed(decimal), decimal) + ' ' + name}
                                                                </p>
                                                                <p className='p-l-5 fs-13 txt-white'>
                                                                    {`(${rate_display})`}
                                                                </p>
                                                            </div>
                                                            <p className='fs-15 txt-theme'>
                                                                $ {currencyFormat(walletAmount, decimal)}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                        } */}
                                        {/* <div className='p-tb-10 dis-flex flex-m flex-sb-m' style={paymentMethods[state.paymentMethod] ? { borderTop: '1px solid ' + theme.palette.gray.main } : null}>
                                            <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>{t('plan.planPrice')}</Typography>
                                            <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}><b>$ {currencyFormat(selectedPlan?.price)}</b></Typography>
                                        </div> */}
                                        {/* <div className='p-b-10 dis-flex flex-m flex-sb-m'>
                                            <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>{t('plan.serviceFee')}{selectedPlan?.service_fee?.['type'] === 'percent' && `(${selectedPlan?.service_fee?.['amount']}%)`}</Typography>
                                            <Typography style={{ fontSize: 17, color: theme.palette.gray.main }}>
                                                <b>$ {_.size(selectedPlan?.service_fee) > 0 ? (currencyFormat((serviceFee).toFixed(2))) : ((0).toFixed(2))}</b>
                                            </Typography>
                                        </div> */}
                                        {/* <div className='p-tb-10 dis-flex flex-m flex-sb-m fs-20'>
                                            <p className='text-gold-short'>{t('plan.totalAmount')}</p>
                                            <p className='text-gold-short'>$ {currencyFormat((totalAmount).toFixed(2),2)} </p>
                                        </div> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container sx={{ position: {xs: 'relative', sm: 'relative'}, width: {xs: '100%', sm: '85%'}, bottom:0 , }}>
                        <Grid item xs={6} sm={6} style={{ padding: 10 }}>
                            <Button
                                variant="outlined"
                                className='pos-absolute'
                                fullWidth
                                onClick={handleBack}
                            >
                                {t('button.back')}
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                className='pos-absolute'
                                fullWidth
                                onClick={purchasePlan}
                            >
                                {t('button.confirm')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepFour = () => {
        return (
            <div className='p-tb-30'>
                <div className='p-b-15 p-lr-15 pos-relative flex-c flex-col bor-10 gradient-gold-border'>
                    <div className='w-full pos-relative' style={{ top: 20, paddingBottom: 50 }}>
                        <div className='p-b-20 txt-center'>
                            <Typography variant='h6' className='text-gold'>
                                <b>{t('receipt.title')}</b>
                            </Typography>
                            <Typography variant="body2" >
                                {result?.created_at}
                            </Typography>
                        </div>
                        <Grid container spacing={1} style={{ padding: 20 }}>
                            <Grid item xs={12} sm={4} style={{textAlign: 'center'}}>
                                <img src={selectedPlan?.plan_logo ? selectedPlan?.plan_logo?.file_name : '/images/logos/logo.png'} alt="plan_logo" loading='lazy' style={{ width: '70%', aspectRatio: 1 / 1 }} />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <Divider orientation="vertical" sx={{ width: {xs: '100%', sm: 4}, height: {xs: 4, sm: '100%'} }} style={{ boxShadow: 'rgb(0 0 0) 6px 0px 2px 0px inset, rgb(255 255 255 / 11%) 2px 1px 3px 0px'}}/>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='p-b-10 w-full'>
                                    <Typography variant='h6' className='p-b-10 text-gold-short' sx={{ textAlign: {xs: 'center', sm: 'left'} }} style={{ color: '#565656', lineHeight: 1.1 }}><b>{selectedPlan?.name ? selectedPlan?.name?.[i18n.language] : selectedPlan?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></Typography>
                                    {_.size(selectedPlan.description) > 0 ? <Typography variant='body2' sx={{ textAlign: {xs: 'center', sm: 'left'} }} style={{ color: theme.palette.white.main, minHeight: 20 }}>{(selectedPlan?.description?.[i18n.language]?.title ? selectedPlan?.description?.[i18n.language]?.title : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.title)}</Typography> : ""}
                                    {_.size(selectedPlan.description) > 0 ? <Typography variant='body2' sx={{ textAlign: {xs: 'center', sm: 'left'} }} style={{ color: theme.palette.white.main, minHeight: 20 }}>{(selectedPlan?.description?.[i18n.language]?.subtitle ? selectedPlan?.description?.[i18n.language]?.subtitle : selectedPlan?.description?.[process.env.REACT_APP_DEFAULT_LANGUAGE]?.subtitle)}</Typography> : ""}
                                    <p className='p-b-10 lh-11 txt-content'><b>{t('plan.merchantName')}: {selectedMerchant?.name ? selectedMerchant?.name?.[i18n.language] : selectedMerchant?.name?.[process.env.REACT_APP_DEFAULT_LANGUAGE]}</b></p>
                                    <p className='txt-white' style={{minHeight: 20 }}>{t('plan.merchantEmail')}: {selectedMerchant?.email ? selectedMerchant?.email : ""}</p>
                                </div>

                                {
                                    // result?.ulid &&
                                    // <div>
                                    //     <Typography variant="body2" style={{ color: theme.palette.gray.main, marginRight: 20 }}>{t('receipt.refNo')}</Typography>
                                    //     <Typography variant="body2" style={{ color: theme.palette.gray.main, width: '70%', wordBreak: 'break-word' }}>{result?.ulid}</Typography>
                                    // </div>
                                }

                                <div className='p-t-10 flex-sb-m w-full fs-20'>
                                    <p className='text-gold-short'><b>{t('receipt.amount')}</b></p>
                                    <div className='flex-r' style={{ width: '70%' }}>
                                    <p className='txt-right text-gold-short'>
                                        <b>{'$ ' + currencyFormat((totalAmount).toFixed(2),2)}</b>
                                    </p>
                                    </div>
                                </div>

                                {
                                    _.size(result?.payment_methods) > 0 &&
                                    <Box style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="body2" style={{ marginBottom: 5, color: theme.palette.white.main }}>{t('receipt.paymentMethod')} </Typography>
                                        <Box style={{ margin: 0, display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                                            {
                                                _.size(result?.payment_methods) > 0
                                                    ? _.map(result?.payment_methods, (payment, key) => (
                                                        <Typography key={key} variant="body2" style={{ color: theme.palette.white.main }}>{payment}</Typography>
                                                    ))
                                                    : <Typography variant="body2" style={{ color: theme.palette.white.main }}>-</Typography>
                                            }
                                        </Box>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container sx={{ position: {xs: 'relative', sm: 'relative'}, width: {xs: '100%', sm: '85%'}, bottom: 0 }}>
                        <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                            <Button
                                variant="outlined"
                                className='pos-absolute'
                                fullWidth
                                onClick={() => navigate('/plan-histories', { state: { from: storePaths(location) } })}
                            >
                                {t('planHistory.viewPlanHistories')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                className='pos-absolute'
                                fullWidth
                                onClick={handleReset}
                            >
                                {t('planHistory.purchaseOtherPlan')}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            case 2:
                return stepThree();
            case 3:
                return stepFour();
        }
    }

    return (
        <>
            <Box style={{ padding: '0 0px 0px 35px', minHeight: '90vh', marginTop: 80, overflowY: 'hidden' }}>
                <Stepper activeStep={activeStep} className='p-b-30' orientation="vertical">
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label}>
                                <StepLabel>
                                    <Typography>{label}</Typography>
                                </StepLabel>
                                <StepContent>
                                    {stepperDisplay(activeStep)}
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </>
    )
}

// const CustomDiv = styled('div')({
//     paddingTop: 30,
//     '@media (min-width: 375px)': {
//         paddingTop: 80,
//     },
// });

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: {
        top: 'auto',
        left: 0,
        right: 0,
        bottom: 0,
        height: 'auto',
        maxHeight: '100%',
        // maxWidth: 444,
        overflow: 'hidden',
        margin:'0 auto',
        borderRadius:'30px 30px 0px 0px'
    }
}));